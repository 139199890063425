import React from 'react'
import { Link } from 'gatsby'

export const Button = ({ href = '/', text, version = 'primary', handleClick, disabled }) => {
  return (
    <Link 
      to={href} 
      onClick={handleClick}
      className={`btn btn--${version} ${disabled ? 'disabled' : ''}`}
    >
      { text }
    </Link>
  )
}

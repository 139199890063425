import React from 'react'
import { SEO } from '../components/SEO'
import { Button } from './../components/Button'

const NotFoundPage = () => (
  <main className="error-page">
    <SEO title="404: Not found" />
    <h1>404</h1>
    <h2>Whooops! You're moved so fast and got lost...</h2>
    <p>The page you are looking for might have been removed or it's temporary unavailable right now.</p>
    <Button 
      version={'white'}
      text="Back"
      href="/"
    />
  </main>
)

export default NotFoundPage
